import React, { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Loader } from './component/Loader';
const ErrorPage = lazy(() => import('./pages/error'));
const SearchPage = lazy(() => import('./pages/search'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

function App() {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <HelmetProvider>
        <div className="min-h-screen">
          <Suspense fallback={<Loader />}>
            <ErrorBoundary
              onReset={reset}
              fallbackRender={(props) => <ErrorPage {...props} />}
            >
              <Router>
                <Routing />
              </Router>
            </ErrorBoundary>
          </Suspense>
        </div>
      </HelmetProvider>
    </QueryClientProvider>
  );
}
function Routing() {
  const location = useLocation();
  return (
    <Switch>
      <Route path="/" children={<SearchPage />} />
      <Redirect to={{ ...location, pathname: `/` }} />
    </Switch>
  );
}

export default App;
